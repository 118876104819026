<messages>["./Login"]</messages>

<template>
  <div
    class="mt-6 centered">
    <v-card style="max-width: 500px" class="centered">
      <form
        novalidate
        @submit.prevent="onLogin()">
        <v-card-title primary-title>
          <div
            v-t="'login.headline'"
            class="text-h5"/>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="username"
            name="username"
            spellcheck="false"
            :label="$t('login.username.label')"
            :error-messages="requiredErrors(
              'username',
              'login.username.required')"
            class="required"
            @input="$v.username.$touch()"
            @blur="$v.username.$touch()"/>
          <v-text-field
            v-model="password"
            name="password"
            spellcheck="false"
            :label="$t('login.password.label')"
            :error-messages="requiredErrors(
              'password',
              'login.password.required')"
            :append-icon="isPasswordHidden ? 'visibility' : 'visibility_off'"
            :type="isPasswordHidden ? 'password' : 'text'"
            class="required"
            @click:append="isPasswordHidden = !isPasswordHidden"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"/>
          <div class="text-center">
            <v-btn type="submit">
              <div
                v-if="!isWorking"
                v-t="'login.action.label'"/>
              <div v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"/>
              </div>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <router-link
            :to="{name: 'passwordReset'}"
            v-text="$t ('login.restore.label')"/>
          <v-spacer/>
        </v-card-actions>
      </form>
    </v-card>
  </div>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'Login',

    mixins: [validationMixins],

    data: () => ({
      username: '',
      password: '',
      isPasswordHidden: true,
      isWorking: false
    }),

    validations: {
      username: {required},
      password: {required}
    },

    computed: {
      ...mapState ({
        isAuthenticated: state => state.auth.isAuthenticated,
        desiredRoute: state => state.router.desiredRoute,
        operationMode: state => state.auth.operationMode
      })
    },

    methods: {
      ...mapMutations ('router', [
        'resetDesiredRoute'
      ]),

      ...mapActions ({
        login: 'auth/login',
        logout: 'auth/logout',
        redirectAfterLogin: 'router/redirectAfterLogin'
      }),

      async onLogin () {
        this.isWorking = true

        if (!this.$v.$invalid) {
          const success = await this.login ({
            userName: this.username,
            password: this.password
          })

          console.log (`I'm ${this.isAuthenticated ? '' : 'not '}authenticated`)

          if (success) {
            if (this.operationMode) {
              switch (this.operationMode) {
                case 'TWO_FACTOR_AUTH_INIT':
                  this.$router.push ({name: 'totp.init'})
                  break

                case 'TWO_FACTOR_AUTH':
                  this.$router.push ({name: 'totp'})
                  break

                default:
                  console.warn (
                    `The operation mode '${this.operationMode}' is unknown ` +
                      'and could not be handled.')
              }
            } else {
              this.redirectAfterLogin (this.$router)
            }
          } else {
            await this.logout ()
          }
        } else {
          this.$v.$touch ()
        }

        this.isWorking = false
      }
    }
  }
</script>

<style scoped>
.centered {
  margin-left: auto;
  margin-right: auto;
}
</style>
